<template>
  <div>
    <div class="maintitle">商品列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-button type="success" class="margin-right" @click="add">添加商品</el-button>
    </el-row>
    <el-table :data="list" border>
      <el-table-column align="center" prop="name" label="商品名称"></el-table-column>
      <el-table-column align="center" prop="jifen" label="需要积分数"></el-table-column>
      <el-table-column align="center" prop="detail" label="简介"></el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==1">正常</span>
          <span v-else>下架</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button v-if="scope.row.status==1" size="small" @click="del(scope)">下架</el-button>
            <el-button v-else size="small" @click="del(scope)">上架</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      total: 0,
      page: 1
    };
  },
  computed: {},
  components: {},
  methods: {
    getlist: function() {
      if (this.crntdistrict == "") {
        this.$message({
          type: "error",
          message: "请选择地区"
        });
        return;
      }
      this.$axios({
        url: this.HOST + "/jiameng/jifengoods/getGoodsList",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    add() {
      this.$router.push("/goods/add");
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    del(scope) {
      let status = scope.row.status==1?0:1;
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/jifengoods/delGoods",
        data: { id: scope.row.id,status:status }
      }).then(res => {
        if (res.data.status == 1) {
          scope.row.status = status;
        }
      });
    }
  },
  created: function() {
    this.getlist();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>